<template>
  <main class="page __no-header" id="updatePassword">
    <div class="wrapper">
      <div class="grid">
        <section>
          <router-link tag="a" :to="{ name: 'home' }">
            <img src="img/logos/logo_full@2x.png" alt="">
          </router-link>
        </section>
        <section class="content">
          <div>
            <span v-if="!reset">
              <h4>Email verificado com sucesso.</h4>
              <h5>Configure a sua senha.</h5>
            </span>
            <span v-else>
              <h4>Atualize sua senha</h4>
            </span>
            <form class="grid" @submit.prevent="confirmed && strength > 2  ? updatePassword() : null">
              <vue-password-auto :strengthMessages="strengthMessages" @strength-updated="strength = $event" :strength="3" placeholder="Senha" v-model="password" type="password"  required ></vue-password-auto>
              <vue-password placeholder="Confirme sua senha" v-model="confirmation" type="password" :disableStrength="true" required></vue-password>
              <button type="submit" :class="[{ __confirmed: confirmed && strength > 2 }, '__small']">{{ (confirmed && strength > 2) ? 'Atualizar' : 'Invalido' }}</button>
            </form>
            <div v-if="strength > 0" :class="['strength', `__${strength}`]">
              {{ strengthMessages[strength] }}
            </div>
          </div>
          <footer>
            &copy; 2019 Todos os direitos reservados. RACE é uma marca registrada pelo INPI.
            <nav>
              <ul>
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </nav>
          </footer>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { VuePassword, VuePasswordAuto } from 'vue-password'
import { header } from '../api.js'
export default{
  name: 'updatePassword',
  data () {
    return {
      password: '',
      token: null,
      confirmation: '',
      strength: 0,
      strengthMessages: ['Senha muito fraca', 'Senha fraca', 'Senha mediana', 'Senha forte', 'Senha muito forte']
    }
  },
  computed: {
    confirmed () {
      return this.confirmation ? (this.password === this.confirmation) : null
    },
    reset () {
      return this.$route.query.reset === 'true' || this.$route.query.reset === true
    }
  },
  beforeMount () {
    this.email = JSON.parse(window.localStorage.getItem('submission')).email
    if (this.$route.query.token) this.find()
  },
  mounted () {
  },
  methods: {
    updatePassword () {
      this.$Progress.start()
      this.$http.put(this.$api({ target: 'update-password' }), { password: this.password }, {
        headers: header(JSON.parse(window.localStorage.getItem('verifyAuthTokens')))
      }).then(response => {
        this.$Progress.finish()
        this.$router.push({ name: 'orientations', query: { reset: this.reset } })
        console.log(response)
      })
    }
  },
  components: {
    VuePassword,
    VuePasswordAuto
  }
}
</script>
