<template>
  <main id="resetPassword" class="page __no-header">
    <div class="wrapper">
      <div class="grid">
        <section>
          <router-link tag="a" :to="{ name: 'home' }">
            <img src="img/logos/logo_full@2x.png" alt="">
          </router-link>
        </section>
        <section class="content">
          <div>
            <h4>Confirme o seu email!</h4>
            <p>Com qual o código encontrado no seu email ?<br>Precisamos desse passo para garantir a sua segurança.</p>
            <form @submit.prevent="verify" class="grid">
              <input v-model="token" type="text" value="" name="email" placeholder="Código" id="" required/>
              <button type="submit">Confirmar</button>
            </form>
          </div>
          <footer>
            <p>
            &copy; 2019 Todos os direitos reservados. RACE é uma marca registrada pelo INPI.
            </p>
            <nav>
              <ul>
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </nav>
          </footer>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { header } from '../api.js'
import auth from '../mixins/auth.js'
export default{
  name: 'resetPassword',
  mixins: [auth],
  data () {
    return {
      email: null,
      token: null,
      url: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(that => {
      if (that.$route.query.verifyLink) next()
      else next({ name: 'checkout' })
    })
  },
  beforeMount () {
    this.url = this.$route.query.verifyLink
    this.email = JSON.parse(window.localStorage.getItem('reset-password')).email
  },
  mounted () {
  },
  methods: {
    verify () {
      this.$Progress.start()
      this.getAuthToken().then(response => {
        window.localStorage.setItem('verifyAuthTokens', JSON.stringify(response.data))
        this.$http.get(this.$route.query.verifyLink, {
          headers: header(response.data)
        }).then(response => {
          this.$Progress.finish()
          this.$router.push({ name: 'updatep', query: { reset: true } })
        }).catch(err => {
          console.log(err)
          this.$Progress.fail()
        })
      })
    }
  }
}
</script>
