<template lang="html">
  <main class="page" id="network">
    <div class="wrapper">
      <div v-if="network" v-html="network.content.rendered" class="wordpressContent"></div>
      <div class="columns">
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/loan-1@2x.png" alt=""/>
              <h4>REMUNERAÇÃO</h4>
            </header>
            <div class="body">
              <p>O analista receberá por hora de análise. O valor da hora será fornecido pelo próprio analista no momento do cadastro.</p>
              <!-- <router&#45;link tag="button" :to="{ name: 'services' }">SAIBA MAIS</router&#45;link> -->
              <!-- <button>SAIBA MAIS</button> -->
            </div>
          </article>
        </div>
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/time@2x.png" alt=""/>
              <h4>FLEXIBILIDADE</h4>
            </header>
            <div class="body">
              <p>O trabalho pode ser feito em qualquer dia e horário da semana, contanto que sejam cumpridos os prazos pré-estabelecidos.</p>
              <!-- <router&#45;link tag="button" :to="{ name: 'services' }">SAIBA MAIS</router&#45;link> -->
            </div>
          </article>
        </div>
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/hours@2x.png" alt=""/>
              <h4>FREELANCE</h4>
            </header>
            <div class="body">
              <p>Por se tratar de serviços "ad hoc" não se caracteriza vínculo empregatício.</p>
              <!-- <router&#45;link tag="button" :to="{ name: 'services' }">SAIBA MAIS</router&#45;link> -->
            </div>
          </article>
        </div>
      </div>
      <h2>CADASTRE SEU PERFIL PARA APROVAÇÃO</h2>
      <h5>Seu perfil será analisado e entraremos em contato em breve.</h5>
      <form @submit.prevent="send">
        <div class="grid">
          <input v-model="form.name" type="text" value="" name="name" placeholder="Nome completo" id="" required/>
          <input v-model="form.phone" type="text" v-mask="['(##) ####-####', '(##) # ####-####']" value="" name="phone" placeholder="Telefone" id=""/>
        </div>
        <div class="grid">
          <input v-model="form.email" type="email" value="" name="email" placeholder="Email" id="" required/>
          <input v-model="form.lattes_perfil" type="url" value="" name="lattes_perfil" placeholder="http://lattes.cnpq.br/4259409970128066" id="" :disabled="form.type === 1"/>
        </div>
        <div class="grid">
          <input v-model="form.experience" type="text" value="" name="experience" placeholder="Areas de Experiência" id=""/>
          <input v-model="form.area" type="text" value="" name="area" placeholder="Formação Acadêmica" id="" :disabled="form.type === 1"/>
          <input v-model="form.titulation" type="text" value="" name="titulation" placeholder="Titulação máxima" id="" :disabled="form.type === 1"/>
        </div>
        <div class="container">
          <span>
          <input v-model="form.type" :value="2" type="radio" id="cpf" name="cpf"/>
            <label for="cnpj">
              Pessoa Física
            </label>
          </span>
          <span>
            <input v-model="form.type" :value="1" type="radio" id="cnpj" checked />
            <label for="cnpj">
              Pessoa Jurídica
            </label>
          </span>
        </div>
        <button type="submit">ENVIAR</button>
      </form>

    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'network',
  props: ['scroll'],
  data () {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        area: null,
        experience: null,
        titulation: null,
        lattes_perfil: null,
        type: 2
      }
    }
  },
  computed: {
    ...mapGetters([
      'network'
    ])
  },
  mounted () {
  },
  methods: {
    send () {
      this.$Progress.start()
      let data = {
        rh: {
          name: this.form.name,
          phone: this.form.phone,
          area: this.form.area,
          titulation: this.form.titulation,
          lattes_perfil: this.form.lattes_perfil,
          experience: this.form.experience
        },
        user: {
          email: this.form.email,
          type: this.form.type
        }
      }

      this.$http.post(this.$api({ target: 'register' }), data).then(response => {
        this.$Progress.finish()
        window.localStorage.setItem('submission', JSON.stringify({ email: this.form.email, id: response.data.user.id }))
        this.$router.push({ name: 'checkout' })
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.$toasted.show('Algo de errado aconteceu, tente novamente!', {
          theme: 'primary',
          position: 'top-center',
          duration: 5000,
          type: 'error',
          onComplete: () => {
            // this.form = {
            //   name: null,
            //   email: null,
            //   phone: null,
            //   area: null,
            //   experience: null,
            //   titulation: null,
            //   lattes_perfil: null,
            //   type: 1
            // }
          }
        })
      })
    }
  },
  components: {
  }
}
</script>
