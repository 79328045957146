import { index } from '../api.js'
const state = {
  pages: undefined,
  home: undefined,
  services: undefined,
  about: undefined,
  network: undefined
}

const getters = {
  pages: () => state.pages,
  home: () => state.home,
  services: () => state.services,
  about: () => state.about,
  network: () => state.network
}

const mutations = {
  SET_PAGES (state, pages) {
    state.pages = pages
    state.home = pages[index.home]
    state.about = pages[index.about]
    state.services = pages[index.services]
    state.network = pages[index.network]
  }
}

const actions = {
  setPages ({ commit }, that) {
    that.$http.get(that.$cms('custom_page_api')).then(response => {
      return new Promise((resolve, reject) => {
        commit('SET_PAGES', response.data)
      })
    })
  }
}

export default {
  state, getters, mutations, actions
}
