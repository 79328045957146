import { client } from '../api'
export default {
  data () {
    return {
      email: null,
      token: null
    }
  },
  methods: {
    getAuthToken () {
      const data = { username: this.email, password: this.token }

      Object.assign(data, client(), { grant_type: 'password', scope: '' })
      return new Promise((resolve, reject) => {
        this.$http.post(this.$api({
          target: 'oauth/token',
          secure: false
        }), data).then(response => {
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
