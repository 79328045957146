<template>
  <main class="page __no-header" id="checkout">
    <div class="wrapper">
      <div class="grid">
        <section>
          <router-link tag="a" :to="{ name: 'home' }">
            <img src="img/logos/logo_full@2x.png" alt="">
          </router-link>
        </section>
        <section class="content">
          <div>
            <div v-if="$route.query.r === undefined">
              <h4>Email enviado!</h4>
              <p>Enviamos uma mensagem para {{ email }} com um link para ativar sua conta.</p>
              <a @click="resend">Não recebeu o email? Reenviar.</a>
              <transition name="fade">
                <p v-if="resent">Email reenviado, caso não tenha recebido, verifique sua caixa de spam.</p>
                <p v-else-if="resent === false">Email já verficado.</p>
              </transition>
            </div>
            <div v-else>
              <h4>Algo de errado aconteceu!!!</h4>
              <p>O link utilizado expirou.</p>
              <a @click="resend">Reenviar.</a>
            </div>
          </div>
          <footer>
            &copy; 2019 Todos os direitos reservados. RACE é uma marca registrada pelo INPI.
            <nav>
              <ul>
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </nav>
          </footer>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'
import { client, header } from '../api.js'
import auth from '../mixins/auth.js'
export default{
  name: 'checkout',
  mixins: [auth],
  data () {
    return {
      id: null,
      resent: undefined
    }
  },
  beforeMount () {
    this.email = JSON.parse(window.localStorage.getItem('submission')).email
    this.id = JSON.parse(window.localStorage.getItem('submission')).id
  },
  mounted () {
    this.$http.get(this.$api({ target: `uca/${this.id}` })).then(response => {
      this.token = `${this.id}${moment(response.data).unix()}`
    })
  },
  methods: {
    resend () {
      this.$Progress.start()
      this.getAuthToken().then(response => {
        this.$http.get(this.$api({ target: 'email/resend' }), {
          headers: header(response.data)
        }).then(response => {
          this.$Progress.finish()
          this.resent = true
          console.log(response)
        }).catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.resent = false
        })
      })
    },
    getAuthToken () {
      const data = { username: this.email, password: this.token }

      Object.assign(data, client(), { grant_type: 'password', scope: '' })
      return new Promise((resolve, reject) => {
        this.$http.post(this.$api({
          target: 'oauth/token',
          secure: false
        }), data).then(response => {
          resolve(response)
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  }
}
</script>
