<template lang="html">
  <main class="page" id="services">
    <div class="wrapper wordpressContent" v-html="about.content.rendered">
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'about',
  props: ['scroll'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'about'
    ])
  },
  mounted () {
  },
  components: {
  }
}
</script>
