<template lang="html">
  <main class="page" id="contact">
    <div class="wrapper">
      <div class="columns">
        <div class="column">
          <h3>FALE CONOSCO</h3>
          <form @submit.prevent="send">
            <input v-model="name" type="text" value="" name="name" placeholder="Nome" id="" required/>
            <input v-model="email" type="email" value="" name="email" placeholder="Email" id="" required/>
            <input v-model="phone" type="text" v-mask="['(##) ####-####', '(##) # ####-####']" value="" name="phone" placeholder="Telefone" id="" required/>
            <input v-model="subject" type="text" value="" name="subject" placeholder="Assunto" id=""/>
            <textarea v-model="message" name="message" placeholder="Do que você precisa?" id=""></textarea>
            <button type="submit">ENVIAR</button>

          </form>
        </div>
        <div class="column">
          <!-- <h3>DEPOIMENTOS</h3> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'about',
  props: ['scroll'],
  data () {
    return {
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    send () {
      let data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message
      }
      this.$http.post(this.$api({
        target: 'contact'
      }), data).then(response => {
        this.$toasted.show('Obrigado por entrar em contato! Em breve retornaremos.', {
          theme: 'primary',
          position: 'top-center',
          duration: 5000,
          type: 'success',
          onComplete: () => {
            this.$router.push({ name: 'home' })
          }
        })
      }).catch(err => {
        console.log(err)
        this.$toasted.show('Algo de errado aconteceu, tente novamente!', {
          theme: 'primary',
          position: 'top-center',
          duration: 5000,
          type: 'error',
          onComplete: () => {
            this.form = {
              name: null,
              email: null,
              phone: null,
              subject: null,
              message: null
            }
          }
        })
      })
    }
  },
  components: {
  }
}
</script>
