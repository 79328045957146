import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Services from './views/Services.vue'
import Network from './views/Network.vue'
import About from './views/About.vue'
import Verify from './views/Verify.vue'
import Checkout from './views/Checkout.vue'
import UpdatePassword from './views/UpdatePassword.vue'
import ResetPassword from './views/ResetPassword.vue'
import ResetPasswordRequest from './views/ResetPasswordRequest.vue'
import Orientations from './views/Orientations.vue'
import Contact from './views/Contact.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  // base: process.env.node_env === 'production' ? '/' : '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: 'services',
      name: 'services',
      component: Services
    },
    {
      path: 'network',
      name: 'network',
      component: Network
    },
    {
      path: 'about',
      name: 'about',
      component: About
    },
    {
      path: 'contact',
      name: 'contact',
      component: Contact
    },
    {
      path: 'checkout',
      name: 'checkout',
      component: Checkout,
      meta: {
        hideHeader: true
      }
    },
    {
      path: 'verify',
      name: 'verify',
      component: Verify,
      meta: {
        hideHeader: true
      }
    },
    {
      path: 'updatep',
      name: 'updatep',
      component: UpdatePassword,
      meta: {
        hideHeader: true
      }
    },
    {
      path: 'resetpr',
      name: 'resetpr',
      component: ResetPasswordRequest,
      meta: {
        hideHeader: true
      }
    },
    {
      path: 'resetp',
      name: 'resetp',
      component: ResetPassword,
      meta: {
        hideHeader: true
      }
    },
    {
      path: 'orientations',
      name: 'orientations',
      component: Orientations,
      meta: {
        hideHeader: true
      }
    }
  ]
})
