<template>
  <main id="resetPassword" class="page __no-header">
    <div class="wrapper">
      <div class="grid">
        <section>
          <router-link tag="a" :to="{ name: 'home' }">
            <img src="img/logos/logo_full@2x.png" alt="">
          </router-link>
        </section>
        <section class="content">
          <div>
            <transition name="fade">
              <div v-if="requested">
                <h4>Recuperação de senha.</h4>
                <p>Um email foi enviando para <strong>{{ email }}</strong> com as instruções necessárias para recuperar a sua conta. </p>
                <p>Seu login, por questões de segurança estará desabilitando até que a senha seja atualizada.</p>
              </div>
            </transition>
          </div>
          <footer>
            <p>
            &copy; 2019 Todos os direitos reservados. RACE é uma marca registrada pelo INPI.
            </p>
            <nav>
              <ul>
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </nav>
          </footer>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import auth from '../mixins/auth.js'
export default{
  name: 'resetPasswordRequest',
  mixins: [auth],
  data () {
    return {
      email: null,
      token: null,
      requested: false,
      url: null
    }
  },
  // beforeRouteEnter (to, from, next) {
  //   next(that => {
  //     if (that.$route.query.email) next()
  //     else next({ name: 'checkout' })
  //   })
  // },
  beforeMount () {
    this.email = this.$route.query.email
  },
  mounted () {
    this.request()
  },
  methods: {
    request () {
      this.$http.post(this.$api({ target: 'password/create' }), { email: this.email }).then(response => {
        window.localStorage.setItem('reset-password', JSON.stringify({ email: this.email }))
        this.requested = true
      }).then(err => {
        console.log(err)
      })
    }
  }
}
</script>
