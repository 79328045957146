<template lang="html">
  <main class="page" id="services">
    <div class="wrapper">
      <h2>
        <span>Nossas formas de Atuação</span>
      </h2>
      <div class="columns" v-if="services">
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/handshake.png" alt=""/>
              <h4>ASSESSORIA</h4>
            </header>
            <div class="body">
              <div v-html="services.acf.abstract_c"></div>
              <!-- <router&#45;link tag="button" :to="{ name: 'services' }">SAIBA MAIS</router&#45;link> -->
            </div>
          </article>
        </div>
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/consulting.png" alt=""/>
              <h4>CONSULTORIA</h4>
            </header>
            <div class="body">
              <div v-html="services.acf.abstract_d"></div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="wrapper wordpressContent" v-html="services.content.rendered"></div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'services',
  props: ['scroll'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'services'
    ])
  },
  mounted () {
  },
  components: {
  }
}
</script>
