import Vue from 'vue'
import axios from 'axios'
import Toasted from 'vue-toasted'
import VueParticles from 'vue-particles'
import VueTheMask from 'vue-the-mask'
import VueProgressBar from 'vue-progressbar'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { cms, api } from './api'

Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$cms = cms
Vue.use(VueParticles)
Vue.use(VueTheMask)
Vue.use(Toasted)
Vue.use(VueProgressBar, {
  color: '#009688',
  failedColor: '#f44366',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
