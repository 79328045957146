/*
 * Items should not be deleted, they should be created by an admin and it's content shoud be edited only
 * Define pages
 * - Início
 * - Serviços
 * - Empresa
 *
 * Define Home Items
 *
*/
import { clientId, clientSecret } from '../.env.js'
const env = process.env.NODE_ENV

// const dev = 'http://localhost/race/wordpress/index.php/wp-json/wp/v2'
const CMS = {
  prod: 'https://cms.race.srv.br/wp-json/wp/v2',
  dev: 'http://cms.race.test/wp-json/wp/v2'
}

const APP = {
  dev: 'http://localhost:8000',
  prod: 'https://prod.race.srv.br'
}

const devIndex = {
  // home: 1,
  // services: 2,
  // about: 0,
  // network: 3
  home: 3,
  services: 2,
  about: 0,
  network: 1
}

const prodIndex = {
  home: 3,
  services: 2,
  about: 0,
  network: 1
}

export const index = env === 'production' ? prodIndex : devIndex

export const cms = target => {
  if (env === 'production') return `${CMS.prod}/${target}`
  return `${CMS.dev}/${target}`
}

export const api = options => {
  let secure = options.secure === undefined ? true : options.secure
  let clerance = options.clerance === undefined ? '' : (options.clerance === 'admin' ? '' : options.clerance + '/')
  if (secure) {
    if (env === 'production') return `${APP.prod}/api/v1/${options.service ? options.service + '/' : ''}${options.target}`
    return `${APP.dev}/api/v1/${clerance}${options.service ? options.service + '/' : ''}${options.target}`
  } else {
    if (env === 'production') return `${APP.prod}/${options.service ? options.service + '/' : ''}${options.target}`
    return `${APP.dev}/${options.service ? options.service + '/' : ''}${options.target}`
  }
}
export const client = () => {
  return {
    client_id: clientId,
    client_secret: clientSecret
  }
}

export const header = (authTokens) => {
  const headers = {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + authTokens.access_token
  }
  return headers
}
