<template id="">
  <footer class="" id="mainFooter">
  </footer>
</template>
<script charset="utf-8">
export default {
  name: 'vFooter',
  components: {
  }
}
</script>
