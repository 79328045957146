<template id="">
  <section class="homeSec" id="network">
    <div class="wrapper">
      <h3>A REDE</h3>
      <div v-if="network" v-html="network.acf.abstract_a"></div>
      <router-link tag="button" :to="{ name: 'network' }">FAÇA PARTE</router-link>
    </div>
    <vue-particles clickMode="repulse" color="#dedede"></vue-particles>
  </section>
</template>
<script charset="utf-8">
import { mapGetters } from 'vuex'
export default {
  name: 'network',
  computed: {
    ...mapGetters([
      'network'
    ])
  }
}
</script>
