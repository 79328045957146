<template>
  <main class="page __no-header" id="orientations">
    <div class="wrapper">
      <div class="grid">
        <section>
          <router-link tag="a" :to="{ name: 'home' }">
            <img src="img/logos/logo_full@2x.png" alt="">
          </router-link>
        </section>
        <section class="content">
          <div v-if="!reset">
            <h4>Parabéns, seu perfil foi submetido com sucesso!</h4>
            <h5>Estamos avaliando o seu perfil.</h5>
            <p>Seu cadastro foi recebido com sucesso e será avaliado, você receberá um email assim que essa etapa seja concluída.</p>
          </div>
          <div v-else>
            <h4>Parabéns, sua senha foi alterada com sucesso!</h4>
          </div>
          <footer>
            &copy; 2019 Todos os direitos reservados. RACE é uma marca registrada pelo INPI.
            <nav>
              <ul>
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </nav>
          </footer>
        </section>
      </div>
    </div>

  </main>
</template>

<script>
export default{
  name: 'orientations',
  computed: {
    reset () {
      return this.$route.query.reset === 'true' || this.$route.query.reset === true
    }
  },
  mounted () {
    if (this.reset) {
      setTimeout(() => {
        window.open('http://localhost:8082', '_blank')
      }, 1000)
    }
  }
}
</script>
