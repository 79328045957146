<template id="">
  <section class="homeSec" id="about">
    <div class="wrapper">
      <h3>EMPRESA</h3>
      <div class="columns">
        <div class="column">
          <article v-if="about" v-html="about.acf.abstract_a"></article>
        </div>
        <div class="column">
          <article id="swiperContainer">
            <swiper v-if="about" :options="swiperOption" :not-next-tick="false" ref="mySwiper">
              <swiper-slide v-for="(abs, index) in Object.keys(about.acf).splice(1, 3)" :key="index">
                <div class="content" v-html="about.acf[abs]"></div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </article>
        </div>
      </div>
      <router-link tag="button" :to="{ name: 'about' }">SAIBA MAIS</router-link>
    </div>
  </section>
</template>
<script charset="utf-8">
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
export default {
  name: 'about',
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        }
        // loop: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'about'
    ])
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>
