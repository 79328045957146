<template id="">
  <section class="homeSec" id="hero" :style="{}">
    <div id="background"></div>
    <div class="wrapper">
      <h2>Transformando dados em informação</h2>
      <h3>Rede de Assessoria e Consultoria em Estatística</h3>
      <router-link tag="button" :to="{ name: 'contact' }">FALE CONOSCO</router-link>
    </div>
  </section>
</template>
<script charset="utf-8">
export default {
  name: 'hero'
}
</script>
