<template>
  <div id="app">
    <v-header v-if="!$route.meta.hideHeader" :scroll="scroll" :solid="$route.name !== 'home'"></v-header>
    <vue-progress-bar></vue-progress-bar>
    <transition name="fade" mode="out-in">
      <router-view :scroll="scroll" v-if="loaded"></router-view>
    </transition>
    <v-footer></v-footer>
    <div id="cover" v-if="!loaded">
      <img class="desktopLogo" src="../public/img/logos/logo_full@2x.png" alt="">
      <!-- <form @submit.prevent="login"> -->
      <!--   <label>Senha</label> -->
      <!--   <input type="password" v&#45;model="password" value="" name="" id="" placeholder="Senha"/> -->
      <!--   <button type="submit">ENTRAR</button> -->
      <!-- </form> -->
      <vue-particles clickMode="repulse" color="#dedede"></vue-particles>
    </div>
    <div id="refresh" v-if="updateExists">
      <p>
      Nova atualização encontrada!
      </p>
      <button @click="refreshApp">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script>
import vHeader from './components/common/Header'
import vFooter from './components/common/Footer'
import detectIt from 'detect-it'
import { mapActions } from 'vuex'
import { client } from './api.js'
export default {
  name: 'app',
  data () {
    return {
      password: null,
      scroll: 0,
      loaded: false,
      refreshing: false,
      updateExists: false,
      registration: null
    }
  },
  computed: {
  },
  created () {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  beforeMount () {
    if (!this.$route.matched.length) {
      const target = this.$route.path.split('/').pop()
      console.log(target)
      if (this.$router.options.routes.map(r => r.name).indexOf(target) !== -1) this.$router.push({ name: target, query: this.$route.query })
      else this.$router.push({ name: 'home' })
    }
    this.setPages(this).then(() => {
      setTimeout(() => {
        this.loaded = true
      }, 2000)
    })
  },
  mounted () {
    window.addEventListener('scroll', e => {
      let scrollNode = document.scrollingElement || document.documentElement
      this.scroll = scrollNode.scrollTop
    }, detectIt.passiveEvents ? { passive: true } : false)
  },
  methods: {
    ...mapActions([
      'setPages'
    ]),
    login () {
      this.getAuthToken().then(response => {
        this.loaded = true
      })
    },
    getAuthToken () {
      const that = this
      const data = {
        username: process.env.NODE_ENV === 'production' ? 'aristotle@race.srv.br' : 'admin@admin.com',
        password: this.password
      }

      Object.assign(data, client(that.$store.getters.conn), { grant_type: 'password', scope: '' })
      return new Promise((resolve, reject) => {
        that.$http.post(that.$api({
          target: 'oauth/token',
          secure: false,
          conn: that.$store.getters.conn
        }), data).then(response => {
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage('skipWaiting')
    }
  },
  components: {
    vFooter,
    vHeader
  }
}
</script>

<style lang="scss">
  @import "./scss/main.scss";
</style>
