<template id="">
  <section class="homeSec" id="services">
    <div class="wrapper">
      <h3>SERVIÇOS</h3>
      <h4>Várias mentes pensando no mesmo propósito
      para auxiliar a realizar o seu objetivo!</h4>
      <div class="columns" v-if="services">
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/handshake.png" alt=""/>
              <h4>ASSESSORIA</h4>
            </header>
            <div class="body">
              <div v-html="services.acf.abstract_a"></div>
              <!-- <router&#45;link tag="button" :to="{ name: 'services' }">SAIBA MAIS</router&#45;link> -->
            </div>
          </article>
        </div>
        <div class="column">
          <article>
            <header>
              <img src="/img/icons/consulting.png" alt=""/>
              <h4>CONSULTORIA</h4>
            </header>
            <div class="body">
              <div v-html="services.acf.abstract_b"></div>
            </div>
          </article>
        </div>
      </div>
      <router-link class="__large" tag="button" :to="{ name: 'services' }">SAIBA MAIS</router-link>
    </div>
  </section>
</template>
<script charset="utf-8">
import { mapGetters } from 'vuex'
export default {
  name: 'services',
  computed: {
    ...mapGetters([
      'services'
    ])
  },
  components: {
  }
}
</script>
