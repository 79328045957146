<template id="">
  <header id="mainHeader" :class="{__solid: (scroll > 50 || solid)}">
    <div class="wrapper">
      <div class="columns">
        <div class="column">
          <router-link tag="div" id="logoContainer" :to="{ name: 'home' }">
            <img class="desktopLogo" src="../../../public/img/logos/logo_full@2x.png" alt="">
          </router-link>
        </div>
        <div class="column">
          <nav id="desktopNav">
            <ul v-if="$route.name === 'home'">
              <li @click="!jumping ? j('hero') : false">INÍCIO</li>
              <li @click="!jumping ? j('services') : false">SERVIÇOS</li>
              <li @click="!jumping ? j('network'):false">REDE</li>
              <li @click="!jumping ? j('about') : false">EMPRESA</li>
              <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              <!-- <li @click="!jumping ? j('fenec') : false">CONTATO</li> -->
            </ul>
            <ul v-else>
              <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
              <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
              <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
              <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
              <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
            </ul>
          </nav>
          <nav id="mobileNav">
            <hamburger
              id="mobileOpen"
              :stroke='3'
              :gap='5'
              color='#00466d'
              :open.sync='mobileOpen'>
            </hamburger>
            <transition name="slideDown">
              <ul v-if="$route.name === 'home' & mobileOpen">
                <li @click="!jumping ? j('hero') : false">INÍCIO</li>
                <li @click="!jumping ? j('services') : false">SERVIÇOS</li>
                <li @click="!jumping ? j('network'):false">REDE</li>
                <li @click="!jumping ? j('about') : false">EMPRESA</li>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
                <!-- <li @click="!jumping ? j('fenec') : false">CONTATO</li> -->
              </ul>
              <ul v-else-if="mobileOpen">
                <router-link tag="li" :to="{ name: 'home' }">INÍCIO</router-link>
                <router-link tag="li" :to="{ name: 'services' }">SERVIÇOS</router-link>
                <router-link tag="li" :to="{ name: 'network' }">REDE</router-link>
                <router-link tag="li" :to="{ name: 'about' }">EMPRESA</router-link>
                <router-link tag="li" :to="{ name: 'contact' }">CONTATO</router-link>
              </ul>
            </transition>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script charset="utf-8">
import jump from 'jump.js'
import hamburger from 'vue-hamburger'
import 'vue-hamburger/index.css'
export default {
  name: 'vHeader',
  props: ['scroll', 'solid'],
  data () {
    return {
      jumping: false,
      mobileOpen: false
    }
  },
  watch: {
    '$route.name' () {
      this.mobileOpen = false
    }
  },
  methods: {
    j (target) {
      this.jumping = true
      this.mobileOpen = false
      jump(`#${target}`, {
        duration: 1000,
        offset: -81,
        callback: () => {
          this.jumping = false
          this.mobileOpen = false
        }
      })
    }
  },
  components: {
    hamburger
  }
}
</script>
