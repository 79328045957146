<template lang="html">
  <main class="page" id="home">
    <hero></hero>
    <services></services>
    <network></network>
    <about></about>
  </main>
</template>

<script>
import hero from '../components/home/Hero'
import services from '../components/home/Services'
import network from '../components/home/Network'
import about from '../components/home/About'
export default {
  name: 'home',
  props: ['scroll'],
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  components: {
    hero,
    services,
    network,
    about
  }
}
</script>
