const env = process.env.NODE_ENV
export const clientId = '2'
// export const clientSecret = 'Ojt1s3uEIZjGu3cwHsRgaj2mKNFm2vAZtdFIubdd' // computador nit
// export const clientSecret = 'ShXo1kQJYFnAVC3RLtBobwLWWyKiXFkkIJuaYp9e' // Camila
export const clientSecret = env === 'production' ? 'c0v4QLsInagn1igtGPNv8Cl2W9FiCkPMTVv6DIrM': 'LsSYYeO9CiWgY96718iOrwHPTK9eAXEnDiPD1Ehn' // Eduardo
export const prod = env === 'production' ? 'ojW299DxGwEq1pW2zhLWMJ8AdtluxdiNUnu1b64j': 'J2Lxnr02QECXFfP7v0s84fVWQWBaBk36J48Bux0l' // Eduardo
export const beta = env === 'production' ? 'ojW299DxGwEq1pW2zhLWMJ8AdtluxdiNUnu1b64j': 'PiXDqI5aBwzLZEJjZTebDdGXIOujooBrv9gLO4XM' // Eduardo
// export const clientSecret = env === 'development' ? '1lZlGqE2fsAePhUk7rFSwmbzdoic2z9J1EkiGeiH': 'qaiQ5PKG1QAWelenJqXnvPxb0QVOU4GZLJjHc1lW' // Eduardo

export const publicSubscriptionKey = 'BGIEoi3any/cWWGpTeyFlMxjM9/y4c482tItXfqlsvMke9xPuoxMjgOsiXt4Tq7nUoS8xe5krhAvmy/afg2V6vY='
